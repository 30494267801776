import "../game-voting/story-view/styles.css"
import React from "react";
import {PlayerAction, PlayerDetails, Story} from "../../../types";
import html2canvas from "html2canvas";
import {saveAs} from "file-saver";
import moment from "moment/moment";
import {storyToText} from "./story-results";
import GamePage from "../game-page/game-page";
import CanvasView from "../../common/canvas-view/canvas-view";
import RoomAPI from "../../../RoomAPI";
import {useAuth0} from "@auth0/auth0-react";
import {makeNice, SafeJoin} from "../../../util";
import {PrevSubmissionRead} from "../../common/prev-submission/prev-submission";

type Props = {
    story: Story
    me?: PlayerDetails
    onBack?: () => void
    onShare?: (story: number, title: string, char1: string, char2: string) => void
    onPermalink?: (story: Story) => void
    onReact?: (playerAction: PlayerAction) => void
    shareText?: string
    loading?: boolean
    subheaders?: string[]
    footerJsx?: JSX.Element
}

export function handleTwitter(story: number, title: string, ...characters: string[]) {
    if (localStorage.getItem("twitter") !== "yes") {
        window.confirm("This auto-populates a tweet but you need to attach the image yourself. Click the \"IMG\" button to download it.")
        localStorage.setItem("twitter", "yes")
    }

    const chars = characters.join('%20and%20')

    const template2 = `https://twitter.com/intent/tweet?via=franticfanfic&text=Check%20out%20this%20%23FranticFanfic%20%27${title}%27%20featuring%20the%20characters%20${chars}!`
    window.open(template2, "_blank")
}

export function cleanStoryName(story: Story) {
    return story.meta.title.replace(/[/\\?%*:|"<>]/g, "_")
}

export function fileNameTxt(story: Story) {
    // return filename.replace(/[/\\?%*:|"<>]/g, "_")

    return `FranticFanfic_${cleanStoryName(story)}_${moment().format("MMM_DD_YYYY")}.txt`
}

export function fileNameImage(story: Story) {
    return `FranticFanfic_${cleanStoryName(story)}_${moment().format("MMM_DD_YYYY")}.png`
}

const StoryView = ({story, onBack, shareText, me, subheaders, footerJsx}: Props) => {


    const {getAccessTokenSilently, isAuthenticated} = useAuth0()

    function titlesJsx() {
        return [
            <span
                className={"underline font-bold break-words"}>{story.meta.title}</span>, <>characters: {SafeJoin(story.characters?.filter(c => c.selected).map<React.ReactNode>(c =>
                <span className={"italic break-words"}
                      key={"character-" + c.id}>{c.name}</span>), ', ')} </>,
            <>writers: {makeNice(story.submissions.map<React.ReactNode>(s => <i
                key={"submission-" + s.id}>{s.player.name}</i>))} </>,
            'Rated ' + (story.settings.mature ? '18+' : 'ALL AGES')]
    }

    function handleTextClick() {
        let text = storyToText(story)
        saveAs(new Blob([text], {type: "text/plain;charset=utf-8"}), fileNameTxt(story))
    }

    async function handleSaveStory(e: React.MouseEvent<HTMLButtonElement>) {
        if (isAuthenticated) {
            const token = await getAccessTokenSilently()

            try {
                await RoomAPI.SaveStory(story.id, token)
            } catch (error) {
                console.log("error - ", error)
            }

        }
    }

    function coolFooter2() {
        let selected = story.characters?.filter(c => c.selected).map(c => c.name) ?? []


        return <div className={"cool-footer"}>
            <button className={"text-2xl mr-1"} disabled={false}
                    onClick={() => (takeScreenshot(story))}>{shareText ? shareText : "💾img"}</button>

            <button className={"text-2xl mr-1"} disabled={false}
                    onClick={handleTextClick}>{shareText ? shareText : "💾txt"}</button>
            {/*{config.accounts && <button className={"text-2xl mr-1"} disabled={false}*/}
            {/*                            onClick={handleSaveStory}>{"save story"}</button>}*/}

            <button className={"text-2xl"}
                    onClick={() => handleTwitter(story.id, story.meta.title.toUpperCase(), ...selected)}>{shareText ? shareText : "🐦twt"}</button>

        </div>
    }


    async function takeScreenshot(story: Story) {
        // let canvas: HTMLCanvasElement = await screeny(canvasId)
        const canvasId = `canvas-${story.id}`
        const canvas = await html2canvas(document.querySelector(`#${canvasId}`) as HTMLElement, {scrollY: -window.scrollY});

        canvas.toBlob(blob => {
            saveAs(blob as Blob, fileNameImage(story))
        }, 'image/png')
    }


    return <GamePage hack={[<CanvasView key={"canvas-" + story.id} story={story}/>]} titlesJsx={titlesJsx()}
                     onBack={onBack} footerJsx={footerJsx ? footerJsx : coolFooter2()}
                     subHeaders={subheaders}>
        <div className={"story-view-container divide-y divide-gray-200"}>
            {story.submissions.map<React.ReactNode>((s, idx) => {

                return <div className={"story-view-submission"} key={"story-" + s.id}>
                    <PrevSubmissionRead submission={s} isCensored={s.meta.censored}/>
                </div>
            })}
        </div>
    </GamePage>

    // <Page subHeaders={subheaders} styled-header={styled-header} onBack={onBack}
    //              footerJsx={footerJsx ? footerJsx : coolFooter2()}
    //              hack={[<CanvasView key={"story-" + story.id} story={story}/>]}>
    //
    // </Page>
}

export default StoryView