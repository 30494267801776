import InputProps from "./input";

type RadioInputProps = InputProps & {
    checked: boolean
    name: string
    label: string

}

const RadioInput: React.FC<RadioInputProps> = (props) => {
    return <><input name={props.name} type="radio" id={props.id} value={props.value}
                    onChange={props.onChange} checked={props.checked}/><label
        htmlFor={props.id}>{props.label}</label></>
}


export default RadioInput