import axios, {AxiosResponse} from "axios";
import {
    BugReport,
    Config,
    FranticFanficUser,
    GameSettings,
    PlayerDetails,
    PlayerId,
    Room,
    RoomMeta,
    Story,
    TwitchStreamer
} from "./types";
import {config} from "./constants";


class RoomAPI {
    static prefix = config.apiHost + "api/v3"


    static GetCount() {
        return axios.get(RoomAPI.prefix + "/counts/").then(res => res.data.count)
    }

    static IncrCount() {
        return axios.post(RoomAPI.prefix + "/counts/").then(res => res.data.count)
    }

    static JoinRoom(roomCode: string, playerDetails: PlayerDetails, token ?: string): Promise<PlayerId> {
        const headers = token ? {headers: {"Authorization": `Bearer ${token}`}} : {}
        return axios.put(RoomAPI.prefix + "/rooms/" + roomCode + "/players", {
            "name": playerDetails.name,
            "secret": playerDetails.secret,
            "meta": {
                "sprite_id": playerDetails.meta.sprite_id,
                "mature": playerDetails.meta.mature,
                "accessibility": playerDetails.meta.accessibility
            },
            "user_id": playerDetails.user_id,
        }, headers)
            .then(res => res.data)

    }

    static JoinRoomAudience(roomCode: string, playerDetails: PlayerDetails, token ?: string): Promise<PlayerId> {
        const headers = token ? {headers: {"Authorization": `Bearer ${token}`}} : {}

        return axios.put(RoomAPI.prefix + "/rooms/" + roomCode + "/audience", {
            "name": playerDetails.name,
            "secret": playerDetails.secret,
            "meta": {
                "sprite_id": playerDetails.meta.sprite_id,
                "mature": playerDetails.meta.mature,
                "accessibility": playerDetails.meta.accessibility
            },
            "user_id": playerDetails.user_id,
        }, headers)
            .then(res => res.data)

    }

    static GetRoom(roomCode: string): Promise<Room> {
        return axios.get(RoomAPI.prefix + "/rooms/" + roomCode)
            .then(res => res.data)

    }

    static ListRooms(): Promise<Room[]> {
        return axios.get(RoomAPI.prefix + "/rooms/")
            .then(res => res.data)

    }

    static CountRooms(): Promise<{ count: number }> {
        return axios.get(RoomAPI.prefix + "/rooms/count")
            .then(res => res.data)

    }

    static CreateRoom(settings: GameSettings, roomMeta: RoomMeta, me: PlayerDetails, auth0Id?: string, token ?: string): Promise<Room> {
        const headers = token ? {headers: {"Authorization": `Bearer ${token}`}} : {}

        return axios.post(RoomAPI.prefix + "/rooms/", {
            "settings": settings, "host": {
                name: me.name,
                meta: {
                    sprite_id: me.meta.sprite_id,
                    mature: me.meta.mature,
                    theme: me.meta.theme,
                    accessibility: me.meta.accessibility
                },
                secret: me.secret,
                user_id: auth0Id
            }, "meta": roomMeta
        }, headers)
            .then(res => res.data)

    }

    // static GetStory(story: string): Promise<Story> {
    //     return axios.get(RoomAPI.prefix + "/stories/" + story).then(res => res.data)
    // }

    static ShareStory(story: number): Promise<Story> {
        return axios.put(RoomAPI.prefix + "/stories/" + story).then(res => res.data)
    }

    static SaveStory(story: number, token: string): Promise<AxiosResponse> {
        return axios.put(RoomAPI.prefix + "/playerStories/" + story, {}, {headers: {"Authorization": `Bearer ${token}`}})
    }

    static RemoveStory(story: number, token: string): Promise<AxiosResponse> {
        return axios.delete(RoomAPI.prefix + "/playerStories/" + story, {headers: {"Authorization": `Bearer ${token}`}})
    }

    static ListStories(token: string): Promise<Story[]> {
        return axios.get(RoomAPI.prefix + "/playerStories", {headers: {"Authorization": `Bearer ${token}`}})
            .then(r => r.data)
    }

    static GetStory(story: number, token: string): Promise<Story> {
        return axios.get(RoomAPI.prefix + "/playerStories/" + story, {headers: {"Authorization": `Bearer ${token}`}})
            .then(r => r.data)
    }

    static GetUser(token: string): Promise<FranticFanficUser> {
        return axios.get(RoomAPI.prefix + "/users", {headers: {"Authorization": `Bearer ${token}`}})
            .then(r => r.data)
    }

    static GetTwitchUsers(): Promise<TwitchStreamer[]> {
        return axios.get(RoomAPI.prefix + "/twitch/streams").then(res => res.data)
    }

    static GetConfig(): Promise<Config> {
        return axios.get(RoomAPI.prefix + "/config").then(res => res.data)
    }

    static GetAdminAccess(secret: string): Promise<number> {
        return axios.get(RoomAPI.prefix + "/config/access", {headers: {"supersecretadminkey": secret}}).then(r => r.status)
    }

    static UpdateConfig(config: Config, secret: string): Promise<Config> {
        return axios.put(RoomAPI.prefix + "/config", config, {headers: {"supersecretadminkey": secret}}).then(res => res.data)
    }

    static SubmitBugReport(bugReport: BugReport, token: string): Promise<void> {
        return axios.post(RoomAPI.prefix + "/bugReport/", {...bugReport}, {headers: {"Authorization": `Bearer ${token}`}})
    }
}

export default RoomAPI