import React, {useEffect, useState} from "react";
import {Character, PlayerAction} from "../../../types";
import './styles.css'
import Waitable from "../../common/waitable/waitable";
import game, {GameStatus} from "../game";
import {useAppSelector} from "../../../redux/store";
import {selectIsSolo, selectPlayerCharacters} from "../../../redux/selectors";
import GameFormPage from "../game-page/game-form-page";
import {useTranslation} from "react-i18next";
import {Maturity} from "../../../types2";

type Props = {
    footerLabel?: string
    onSubmit: (playerAction: PlayerAction) => void
}

export function numberString(num?: number) {
    switch (num) {
        case 0:
            return "zero"
        case 1:
            return "one"
        case 2:
            return "two"
        case 3:
            return "three"
        case 4:
            return "four"
        case 5:
            return "five"
        case 6:
            return "six"
        case 7:
            return "seven"
        case 8:
            return "eight"
        case 9:
            return "nine"
        case 10:
            return "ten"
        default:
            return "?"
    }


}


const CharacterSubmit = ({onSubmit}: Props) => {

    const sortedCharacters: Character[] | undefined = useAppSelector(selectPlayerCharacters)
    let isSolo = useAppSelector(selectIsSolo);


    const settings = useAppSelector(state => state.game.game?.settings)

    const {t} = useTranslation(['game', 'common'])

    const [characterNames, setCharacterNames] = useState(new Map<number, string>())
    const [characterMaturities, setCharacterMaturities] = useState(new Map<number, boolean>())


    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        let id = parseInt(event.target.name)
        setCharacterNames(prevState => new Map(prevState).set(id, event.target.value))
        return event.target.value
    }

    function handleMature(characterId: number, event: React.ChangeEvent<HTMLInputElement>) {
        setCharacterMaturities(prevState => new Map(prevState).set(characterId, event.target.value === "true"))
    }

    useEffect(() => {
        if (!sortedCharacters) {
            return
        }
        setCharacterNames(prevState => {
            const newState = new Map(prevState)
            for (let char of sortedCharacters) {
                if (char.name.length > 0) {
                    newState.set(char.id, char.name)
                }
            }
            return newState
        })
    }, [sortedCharacters])

    useEffect(() => {
        if (!sortedCharacters) {
            return
        }

        setCharacterMaturities(prevState => {
            const newState = new Map(prevState)
            for (let char of sortedCharacters) {
                newState.set(char.id, prevState.get(char.id) ?? settings?.maturity === Maturity.MATURE)

            }
            return newState
        })
    }, [settings, sortedCharacters])

    function gameSubmit() {
        let chars = Array.from(characterNames).map(([id, character]) => (
            {
                id: id,
                name: character,
                meta: {
                    mature: characterMaturities.get(id) ?? (settings?.maturity === Maturity.MATURE)
                }
            }));

        return {
            body: {
                "characters": chars
            },
            code: "game/character",
        }
    }

    function ready() {
        return Array.from(characterNames.values()).every(value => value.length !== 0)
    }

    function subheaders() {
        const numToSubmit = numberString(sortedCharacters?.filter(c => !c.meta.locked)?.length)

        return t('characterSubmit.subheaders.default', {number: numToSubmit})
    }

    return <Waitable playerActionHandler={onSubmit} waitingFor={[GameStatus.GameStatusPostCharacters]}
                     waitHeader={t('characterSubmit.headers.waiting')}>
        <GameFormPage submitText={isSolo ? t('button.letsGo', {ns: 'common'}) : t('characterSubmit.footers.default')}
                      ready={ready()}
                      gameSubmit={gameSubmit}
                      subHeaders={[subheaders()]}
                      titles={[t('characterSubmit.headers.default')]}>
            <div className={"character-submit-form"}>

                {!sortedCharacters?.every(c => !c.meta.otp) &&
                    <div className={"flex flex-col max-w-full"}>
                        {sortedCharacters && sortedCharacters?.filter(c => c.meta.otp).map((v, idx) =>
                            <div className={"shadow-basic max-w-full px-4"} key={idx}>
                                <label htmlFor={'char' + v.id}>{idx + 1}</label>
                                <input className={"max-w-full"} disabled={v.meta.locked} id={'char' + v.id}
                                       autoComplete={"off"}
                                       minLength={1}
                                       maxLength={30}
                                       key={v.id}
                                       name={'' + v.id} type="text"
                                       value={characterNames.get(v.id) ?? ''}
                                       onChange={handleChange} required/>

                            </div>
                        )}
                    </div>
                }

                {sortedCharacters && sortedCharacters?.filter(c => !c.meta.otp).map((v, idx) =>
                    <div key={`char-${v.id}`} className={"flex flex-col mx-1 space-x-2 space-y-1 max-w-full"}>
                        <div className={"flex justify-center items-stretch max-w-full px-4"} key={idx}>
                            <label className={"flex justify-center items-center"}
                                   htmlFor={'char' + v.id}>{idx + 1}</label>
                            <input className={"max-w-full"} disabled={v.meta.locked} id={'char' + v.id} minLength={1}
                                   maxLength={30}
                                   autoComplete={"off"}
                                   key={v.id}
                                   name={'' + v.id} type="text"
                                   value={characterNames.get(v.id) ?? ''}
                                   onChange={handleChange} required/>

                        </div>
                        {settings?.character_maturity &&
                            <div className={"mature-select self-end"}>
                                <div className={"flex flex-row items-stretch cursor-pointer"}>
                                    <input id={`mature-yes-${v.id}`} name={`mature-${v.id}`} type="radio" value="true"
                                           onChange={e => handleMature(v.id, e)}
                                           checked={characterMaturities.get(v.id) === true}/>
                                    <label className={"flex justify-center items-center mx-1 leading-none text-base"}
                                           htmlFor={`mature-yes-${v.id}`}>18+</label>

                                    <input id={`mature-no-${v.id}`} name={`mature-${v.id}`} type="radio" value="false"
                                           onChange={e => handleMature(v.id, e)}
                                           checked={characterMaturities.get(v.id) === false}/>
                                    <label className={"flex justify-center items-center mx-1 leading-none text-base"}
                                           htmlFor={`mature-no-${v.id}`}>all ages</label>
                                </div>
                            </div>
                        }
                    </div>)}
            </div>

        </GameFormPage>
    </Waitable>
}

export default CharacterSubmit