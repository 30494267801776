import React from "react";
import {GameSettings} from "../types";
import {textNumber} from "./quick-start";


export const quickStartList = function (settings?: GameSettings) {
    return (
        <>
            <u><b>QUICK START GUIDE</b></u>

            <ol>
                <li> Write {settings ? <b>{textNumber.get(settings.num_characters_submit)}</b> : "some"} characters.
                </li>
                <li> the game will pick {settings ?
                    <b>{textNumber.get(settings.max_characters)}</b> : "some"} characters for
                    you.
                </li>
                <li> Write your story.</li>
                <li> USe the prompts as they appear!</li>

                <li>Title your work.</li>
            </ol>


        </>
    )
}

export const body = {

    // QUICK START GUIDE. INSIDE GAME. AFTER LOBBY. NOT MENU. NOT HOW TO PLAY.
    contentGOTIT: function (settings: GameSettings) {
        return (
            <>
                <div className={"quick-start-ol"}>

                    {/*<b><u>WELCOME TO FRANTIC FANFIC</u></b>*/}
                    {/*<p>Here's an overview on how this game mode works.</p>*/}

                    {quickStartList(settings)}

                    <p>yes! You can save your story after the game.</p>
                    <p>
                        Do not share any personal identifiable information,
                        illegal activities, or otherwise harmful material within these stories.
                        By clicking "GOT IT" you agree to our full <a href="https://franticfanfic.com/terms"
                                                                      target="_blank" rel="noreferrer">Terms of
                        Service</a>.
                    </p>
                </div>


            </>
        )
    },
    // HOW TO PLAY QUICK START BELOW
    contentNOGOTIT: function (settings: GameSettings) {
        return (
            <>
                <div className={"quick-start-ol"}>
                    <p>Frantic Fanfic is a round-robin style story game that is like exquisite corpse but for
                        fanfiction.</p>

                    {quickStartList(settings)}


                </div>


            </>
        )
    }

}

export const footers = {
    submit: "OK, GOT IT!",
    loading: "loading..."

}