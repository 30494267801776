import React, {useEffect, useState} from "react";
import "./styles.css"
import {constants} from "../../../constants";

export type PickerOption<T> = {
    value: T

    labels?: string[]
}


type Props<T> = {
    value: T
    onSelect: (val: T) => void

    options: PickerOption<T>[]
}

export const Picker = ({value, onSelect, options}: Props<number>) => {

    let back = constants.leftButton
    let forward = constants.rightButton

    const [index, setIndex] = useState<number>()

    useEffect(() => {
        setIndex(options?.findIndex(v => v.value === value))
    }, [value, options])

    function handleLeft(_e: React.MouseEvent<HTMLButtonElement>) {
        if (index !== undefined) {
            onSelect(options[(((index - 1) % options?.length) + options.length) % options.length].value)
        }
    }

    function handleRight(_e: React.MouseEvent<HTMLButtonElement>) {
        if (index !== undefined) {
            onSelect(options[(index + 1) % options.length].value)
        }
    }

    return <div className={"picker-container"}>
        <button className={"picker-container-arrow"} onClick={handleLeft} disabled={options.length < 2}
                type="button">{back}</button>
        <div className={"picker-container-label"}>
            {(index !== undefined && options[index].labels !== undefined) ? options[index].labels?.map(l => <div
                key={"label-" + l}>{l}</div>) : <div>{value}</div>}
        </div>

        <button className={"picker-container-arrow"} onClick={handleRight} disabled={options.length < 2}
                type="button">{forward}</button>
    </div>

}

export default Picker