export enum GameMode {
    default,
    otp,
    self_insert,
    broken_telephone,
    solo_mode
}


// NAMES ARE DEFINED HERE
export enum ColourScheme {
    CLASSIC,
    NOIR,
    SPARKLE,
    NEON,
    BLUE,
    NINETIES,
    PETAL,
    BLOOD,
    WORM,
    GOTH,
    HACKER,
    WINTER
}

export enum PromptPack {
    BASIC,
    HARDCORE,
    FLUFF,
    ART,
    DRAMA,
    FANTASY,
    GAMERS,
    HUNGRY,
    PHRASES
}

export enum Maturity {
    MATURE,
    ALL_AGES
}

export enum TextStyle {
    BASIC,
    ITALIC,
    BOLD
}

export enum BugReportFrequency {
    EVERY_TIME,
    HARDLY_EVER,
    OCCASIONALLY,
    ONCE
}

export enum BugReportOtherDevice {
    YES, NO
}