import React, {useContext} from "react";
import Waitable from "../../common/waitable/waitable";
import {GameStatus} from "../game";
import {useAppSelector} from "../../../redux/store";
import GameFormPage from "../game-page/game-form-page";
import {GameContext} from "../../../contexts";
import {useTranslation} from "react-i18next";


const Minors = () => {
    const {submit} = useContext(GameContext)

    const {t} = useTranslation("game", {keyPrefix: "minors"})

    const selectGameSettings = useAppSelector(state => state.game.game?.settings)


    function gameSubmit() {
        return {code: "game/ackMinor", body: {}}
    }

    return <Waitable playerActionHandler={submit} waitingFor={[GameStatus.GameStatusPostMinors]}
                     waitHeader={t("waiting")}>
        <GameFormPage
            readyFlash
            submitText={t('footer.submit')} gameSubmit={gameSubmit}
            titles={[t('headers.default')]}>
            <div className={"flex-grow flex justify-center items-center px-2"}>
                <div className={"fake-button p-4 text-4xl flex justify-center items-center text-center"}>
                    <p>
                        reminder! <br/>there are players under 18 in this session. <br/><br/>
                        As per our <a href={"/codeofconduct"} target={"_blank"}>code of conduct</a>, Do
                        not
                        include mature content in these stories.
                    </p>

                </div>
            </div>
        </GameFormPage>
    </Waitable>


}

export default Minors