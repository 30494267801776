import React, {useEffect, useState} from "react";

import './styles.css'
import "./name.css"
import {PlayerDetails} from "../../../types";
import MenuFormPage from "../menu-page/menu-form-page";
import {useAppDispatch} from "../../../redux/store";
import {updateTheme} from "../../../redux/slices/menu";
import {ColourScheme} from "../../../types2";
import {useUser} from "../auth/is_loggedin";
import {config} from "../../../constants";
import {useTranslation} from "react-i18next";
import ImgPicker from "./imgpicker";
import useKonami from "./konami";
import LoginButton from "./login";
import LogoutButton from "./logout";
import Supporter from "./support";
import ThemeSelect from "./theme-select";
import NameRow from "./name-row";
import TextInput from "./textinput";
import RadioInput from "./radioinput";


type Props = {
    player?: PlayerDetails
    onPlayerSubmit: (playerDetails: PlayerDetails) => void
}


export type Avatar = {
    id: number
    name: string
    premium?: boolean
    konami?: boolean
}

type Theme = {
    id: number
    name: string
    premium?: boolean
}


function getAvatars(premium ?: boolean, konami ?: boolean) {
    return names.filter(a => premium || !a.premium).filter(a => konami || !a.konami || premium)
}

const names: Avatar[] = [
    {id: 0, name: "ghost"},
    {id: 24, name: "cryghost", premium: true},
    {id: 1, name: "demon"},
    {id: 2, name: "raccoon"},
    {id: 3, name: "trash"},
    {id: 4, name: "dog"},
    {id: 5, name: "spike"},
    {id: 6, name: "armpit"},
    {id: 7, name: "cat"},
    {id: 8, name: "rabbit"},
    {id: 9, name: "potato"},
    {id: 25, name: "sparkletato", premium: true},
    {id: 10, name: "buffpire"},
    {id: 26, name: "buffstare", premium: true},
    {id: 11, name: "burger"},
    {id: 12, name: "dino"},
    {id: 13, name: "kitsune"},
    {id: 27, name: "glarefox", premium: true},
    {id: 14, name: "protec"},
    {id: 15, name: "ratty"},
    {id: 16, name: "reapr"},
    {id: 28, name: "reaprlash", premium: true},
    {id: 17, name: "skellybts"},
    {id: 29, name: "pumpedsklly", premium: true},
    {id: 18, name: "teagor"},
    {id: 19, name: "wormman"},
    {id: 30, name: "shinyworm", premium: true},
    {id: 20, name: "hedgee"},
    {id: 21, name: "scrambles"},
    {id: 22, name: "poulet"},
    {id: 23, name: "plat", premium: true, konami: true}
]

const themes: Theme[] = [
    {id: 0, name: "Classic"},
    {id: 1, name: "noir"},
    {id: 2, name: "sparkle", premium: true},
    {id: 3, name: "neon", premium: true},
    {id: 4, name: "blue", premium: true},
    {id: 5, name: "90s", premium: true},
    {id: 6, name: "petal", premium: true},
    {id: 7, name: "blood", premium: true},
    {id: 8, name: "worm", premium: true},
    {id: 9, name: "goth", premium: true},
    {id: 10, name: "hacker", premium: true},
    {id: 11, name: "winter", premium: true},
]


const Name = ({player, onPlayerSubmit}: Props) => {

    const dispatch = useAppDispatch()

    const {t} = useTranslation('main', {keyPrefix: "profile"})


    const user = useUser()

    const [name, setName] = useState<string>(player ? player.name : '')
    const [mature, setMature] = useState(player ? player.meta.mature : false)
    const [accessibility, setAccessibility] = useState(player ? player.meta.accessibility : false)

    const [colorScheme, setColorScheme] = useState<number>(player ? player.meta.theme : ColourScheme.CLASSIC)


    const [curNames, setCurNames] = useState(getAvatars())
    const [spriteId, setSpriteId] = useState(player && player.name ? player.meta.sprite_id : Math.floor(Math.random() * curNames.length))
    const konami = useKonami()


    useEffect(() => {
        setCurNames(getAvatars(user?.premium, konami))

    }, [konami, user?.premium])

    useEffect(() => {
        if (konami) {
            localStorage.setItem("plat", "true")
            setSpriteId(23)
        }
    }, [konami])


    function handleSubmit() {
        if (!name) {
            alert("NO NAME?!")
            return
        }
        onPlayerSubmit({
            name: name,
            meta: {
                sprite_id: spriteId,
                mature,
                theme: colorScheme,
                accessibility
            }
        })

        localStorage.setItem("playerName", name)
        localStorage.setItem("playerMature", mature.toString())
        localStorage.setItem("playerAccessibility", accessibility.toString())
        localStorage.setItem("playerSprite", spriteId.toString())
        localStorage.setItem("playerTheme", colorScheme.toString())
    }


    function handleMaturity(event: any) {
        setMature(event.target.value === 'true')
    }

    function handleAccessibility(event: any) {
        setAccessibility(event.target.value === 'true')
    }

    function handleColorScheme(event: React.ChangeEvent<HTMLInputElement>) {
        const val = Number.parseInt(event.target.value)
        setColorScheme(val)

        dispatch(updateTheme(val))
    }

    function handleName(event: React.ChangeEvent<HTMLInputElement>) {
        setName(event.target.value)

    }


    return <MenuFormPage hideAvatar submit={handleSubmit} titles={[t('headers.default')]}
                         subHeaders={[t('subheaders.default')]} ready={true}>
        <div className={"flex-1 flex flex-col justify-center items-center space-y-4 my-2"}>

            <div className={"flex flex-col text-xl space-y-2"}>
                <LoginButton disabled={user !== undefined}
                             displayText={user !== undefined ? `${t('body.loggedIn')}: ${user.name}` : t('body.logIn')}/>

                <div className={"fake-button leading-none py-1"}>Stories Written: {user?.rank ?? 0}</div>

                <LogoutButton disabled={user === undefined}
                              displayText={t('body.logOut')}/>


            </div>


            <div className={"mx-1 self-stretch md:mx-12"}>
                <ImgPicker spriteId={spriteId} setSpriteId={setSpriteId} curNames={curNames}/>
                {spriteId === 22 ? <div onClick={() => window.open(config.pouletUrl, "_blank")}
                                        className={"avatar4-name cursor-pointer h-4 text-xl flex justify-center items-center leading-none text-center"}>{t('body.poulet')}</div> : spriteId === 23 ?
                    <div
                        className={"avatar4-name text-2xl h-8 flex justify-center items-center leading-none"}>{t('body.secretCharacter')}</div> :
                    <div className={"h-4"}/>}
            </div>


            <NameRow leftLabel={t('body.avatarLabel')}>
                <div id="sprite-name" className={"sprite-name"}>{curNames.find(c => c.id === spriteId)?.name}</div>
            </NameRow>

            <NameRow leftLabel={t('body.nameLabel')}>
                <TextInput onChange={handleName} id={"name-input"} value={name}/>
            </NameRow>


            <NameRow leftLabel={t('body.mature')}>
                <div className={"square-radio-row"}>
                    <RadioInput name={"player-mature"} id={"player-mature-no"} onChange={handleMaturity}
                                checked={!mature} value={"false"} label={"no"}/>
                    <RadioInput name={"player-mature"} id={"player-mature-yes"} onChange={handleMaturity}
                                checked={mature} value={"true"} label={"yes"}/>
                </div>
            </NameRow>

            <NameRow leftLabel={t('body.accessibleFont')}>
                <div className={"square-radio-row"}>
                    <RadioInput name={"player-accessibility"} id={"player-accessibility-no"} onChange={handleAccessibility}
                                checked={!accessibility} value={"false"} label={"no"}/>
                    <RadioInput name={"player-accessibility"} id={"player-accessibility-yes"} onChange={handleAccessibility}
                                checked={accessibility} value={"true"} label={"yes"}/>
                </div>
            </NameRow>

            <NameRow leftLabel={t('body.colorScheme')} bottom={!user?.premium ?
                <div className={"self-center text-center"}>{t('body.extraThemes')}</div> : undefined}>
                <div className={"md:w-3/4 grid grid-cols-3 grid-rows-4 gap-1 mb-4"}>
                    {themes.map(t => <ThemeSelect key={`theme-${t.id}`} value={t.id}
                                                  disabled={t.premium && !user?.premium}
                                                  label={t.name} checked={colorScheme === t.id}
                                                  onChange={handleColorScheme}/>)}
                </div>

            </NameRow>
        </div>
        <div className={"self-center mb-2"}>
            <Supporter/>
        </div>

    </MenuFormPage>

}


export default Name
