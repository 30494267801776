import {GameMessage, PlayerAction, PlayerId, PlayerMessage} from "../../types";
import {useCallback, useEffect, useRef} from "react";
import {config} from "../../constants";
import moment from "moment";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {updateConnected, updateError, updateLoading} from "../../redux/slices/meta";
import {updatePlayer} from "../../redux/slices/player";
import {updateGame} from "../../redux/slices/game";
import {GameStatus} from "./game";

type GameConn = {
    sendMessage: (playerAction: PlayerAction, noLoading?: boolean) => void
    close: () => void
}

function useGame(playerId: PlayerId, playAgain: (happy: boolean) => void, goAway: () => void): GameConn {

    const gameState = useAppSelector(state => state.game)
    const selectPlayerId = useAppSelector(state => state.player.player?.id)
    const selectMenu = useAppSelector(state => state.menu)


    const gameDone = useRef(false)

    const dispatch = useAppDispatch()

    const loadTimer = useRef<NodeJS.Timeout>()

    let webSocket = useRef<WebSocket>()


    const close = useCallback(() => {
        webSocket.current?.close()
    }, [])


    const handleMessage = useCallback((message: any) => {
        dispatch(updateLoading(false))

        if (message === "goaway") {
            close()
            goAway()
            return
        }
        dispatch(updateConnected(true))

        let state = JSON.parse(message);

        let gameMessage = state as GameMessage

        let offset = new Date(gameMessage.now).getTime() - Date.now()
        moment.now = function () {
            return offset + Date.now()
        }

        if (gameMessage.ack) {
            if (loadTimer.current) {
                clearTimeout(loadTimer.current)
            }
            if (gameMessage.ack) {
                dispatch(updateError(""))
                // setError("")
            }
        }

        if (gameMessage.error) {
            if (loadTimer.current) {
                clearTimeout(loadTimer.current)
            }
            dispatch(updateError(gameMessage.error))
        } else {
            dispatch(updateError(""))
        }

        if (gameMessage.player !== undefined) {
            if (gameMessage.player.status === GameStatus.GameStatusInvalid) {
                webSocket.current?.close()
                goAway()
            } else {
                dispatch(updatePlayer(gameMessage.player))
            }
            // setPlayerState(gameMessage.player)
        }

        if (gameMessage.game !== undefined) {
            if (gameMessage.game.status === GameStatus.GameStatusInvalid) {
                webSocket.current?.close()
                goAway()
            } else {
                if (gameMessage.game.status === GameStatus.GameStatusResults) {
                    webSocket.current?.close()
                    gameDone.current = true
                }
                dispatch(updateGame(gameMessage.game))
            }
        }


    }, [close, dispatch, goAway])

    const ping = useCallback(() => {
        if (webSocket.current && webSocket.current?.readyState !== WebSocket.CLOSED) {
            webSocket.current.send("")
        }
    }, [])

    const connect = useCallback((callback?: () => void) => {
        if (gameDone.current) {
            return
        }
        if (webSocket.current && webSocket.current?.readyState !== WebSocket.CLOSED) {
            return
        }
        console.debug("connecting")
        const ws = new WebSocket(`${config.wsHost}/rooms?player_id=${playerId.id}&player_secret=${playerId.secret}&game_id=${playerId.game_id}`);

        ws.onopen = (ev) => {
            console.debug("websocket open")
            dispatch(updateConnected(true))
            if (callback) {
                callback()
            }
        }

        ws.onclose = (ev) => {
            console.debug("websocket close", ev)
            dispatch(updateConnected(false))
        }

        ws.onmessage = (message: MessageEvent) => {
            handleMessage(message.data)
        }

        ws.onerror = (ev) => {
            console.error("websocket error", ev)
            dispatch(updateConnected(false))
        }

        webSocket.current = ws
    }, [dispatch, handleMessage, playerId.game_id, playerId.id, playerId.secret])


    const sendMessageOnly = useCallback((playerAction: PlayerAction, noLoading ?: boolean) => {
        console.debug("send message only")
        const playerMessage: PlayerMessage = {
            game_id: gameState.game?.id as number,
            player_action: playerAction,
            player_id: selectPlayerId as number,
            player_secret: selectMenu.playerId?.secret as string
        }

        const ws = webSocket.current

        // Send message if its open
        if (ws && ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify(playerMessage))
            if (!noLoading) {
                dispatch(updateLoading(true))
                loadTimer.current = setTimeout(() => {
                    dispatch(updateLoading(false))
                    updateError("timed out")
                    // setError(prevState => prevState ?? "timed out")
                }, 5000)
            }

        } else {
            if (!noLoading) {
                dispatch(updateLoading(false))
                if (loadTimer.current) {
                    clearTimeout(loadTimer.current)
                }
            }

        }
    }, [dispatch, gameState.game?.id, selectPlayerId, selectMenu.playerId?.secret])


    const sendMessage = useCallback((playerAction: PlayerAction, noLoading ?: boolean) => {
        console.debug("send message")
        const ws = webSocket.current
        // Force a reconnect
        if (gameDone.current) {
            //no - op
        } else if (!webSocket.current || ws?.readyState === WebSocket.CLOSED) {
            connect(() => sendMessageOnly(playerAction, noLoading))
        } else {
            sendMessageOnly(playerAction, noLoading)
        }


    }, [sendMessageOnly, connect])


    useEffect(() => {
        console.debug("initial connect")
        connect()

        let interval = setInterval(() => {
            if (!gameDone.current) {
                connect()
            }
        }, 5000)

        let pingInterval = setInterval(() => {
            ping()
        }, 30000)

        return () => {
            clearInterval(interval)
            clearInterval(pingInterval)
        }
    }, [connect, ping])

    useEffect(() => {
        window.onbeforeunload = (e: BeforeUnloadEvent) => e.returnValue = 'Are you sure?'

        return () => {
            window.onbeforeunload = null
        }
    }, [])

    const handleOnBeforeUnload = (e: BeforeUnloadEvent) => {
        e.returnValue = 'Are you sure?';
    }

    return {
        sendMessage: sendMessage,
        close: close,
    }
}

export default useGame