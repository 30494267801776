import {constants} from "../../../constants";
import LinkButton from "../../menu/link-button/link-button";

type Props = {
    header: string

    linkPath?: string

    buttonClick?: () => void

    subHeader?: React.ReactNode
}

const ButtonDiv = ({
                       linkPath,
                       buttonClick,
                       text
                   }: { linkPath?: string, buttonClick?: () => void, text: string }) => {
    if (linkPath) {
        return <LinkButton path={linkPath} buttonText={text}/>
    } else if (buttonClick) {
        return <button onClick={buttonClick}>{text}</button>
    } else {
        return <div className={"bg-gray-drk shadow-basic text-center text-4xl p-1"}>{text}</div>
    }

}

const StyledHeader: React.FC<Props> = (props) => {

    return <div className={"flex flex-col flex-1 font-sans"}>
        <div id={"styled-header-container"} className={"uppercase"}>
            <ButtonDiv text={props.header} buttonClick={props.buttonClick} linkPath={props.linkPath}/>
        </div>
        {props.subHeader && <div id={"subheader-container"} className={"ml-8"}>
            <div className={"shadow-basic bg-night-primary border-[1px] border-black text-lg p-1 pl-4"}>
                {props.subHeader}
            </div>
        </div>}
    </div>
}

export default StyledHeader