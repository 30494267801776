import React, {useContext, useEffect, useState} from "react";
import "./styles.css"
import {PlayerAction} from "../../../types";
import {constants} from "../../../constants";
import Waitable from "../../common/waitable/waitable";
import {GameStatus} from "../game";
import {useAppSelector} from "../../../redux/store";
import GameFormPage from "../game-page/game-form-page";
import {GameContext} from "../../../contexts";
import Break from "./break";
import {GameMode} from "../../../types2";
import {useTranslation} from "react-i18next";
import {PrevSubmission} from "../../common/prev-submission/prev-submission";


const StoryTitle = () => {
    const {submit} = useContext(GameContext)

    const gameState = useAppSelector(state => state.game)
    const playerState = useAppSelector(state => state.player)
    const accessibility = useAppSelector(state => state.player.player?.meta.accessibility)

    const [title, setTitle] = useState("")

    const [funOver, setFunOver] = useState(false)

    const [flagged, setFlagged] = useState(new Set<number>())

    const {t} = useTranslation(['game', 'common'])

    function handleTitle(event: React.ChangeEvent<HTMLInputElement>) {
        setTitle(event.currentTarget.value)
        return event.currentTarget.value
    }

    useEffect(() => {
        let timeout = setTimeout(() => {
            setFunOver(true)
        }, 10000);

        return () => {
            clearTimeout(timeout)
        }
    }, [])

    function handleSubmit(): PlayerAction {
        return {
            body: {
                "story": {
                    "id": playerState.player?.current_story?.id,
                    "meta": {
                        "title": title
                    }
                },
                flagged_submissions: Array.from(flagged)
            },
            code: "story/title",
        }
    }

    function handleFlag(checked: boolean, id: number) {
        if (checked) {
            setFlagged(prev => {
                return new Set(prev.add(id))
            })
        } else {
            setFlagged(prev => {
                prev.delete(id)
                return new Set(prev)
            })
        }

    }

    function ready() {
        return title.length > 0
    }

    function waiting() {
        return funOver || playerState.player?.data.audience
    }

    return waiting() ?
        <Waitable playerActionHandler={submit}
                  waitingFor={[GameStatus.GameStatusPostStoryTitle, GameStatus.GameStatusPostCensoring]}
                  waitHeader={t('storyTitle.headers.waiting')}>
            <GameFormPage
                subHeaders={gameState.game?.settings.game_mode !== GameMode.solo_mode ? [t('storyTitle.subheaders.default')] : [t('storyTitle.subheaders.defaultSolo')]}
                ready={ready()}
                titles={[t('storyTitle.headers.default')]}
                gameSubmit={handleSubmit}>
                <div className={"story-title divide-black divide-y"}>
                    <div className={"story-title-container"}>
                        <div className={"story-title-row"}>
                            <label className={"story-title-label"}
                                   htmlFor="story-title-field">{t('label.title', {ns: 'common'})}:</label>
                            <div className={"story-title-field-div"}>
                                <input autoFocus={true} autoComplete={"off"}
                                       className={"story-title-field common-shadow"}
                                       id="story-title-field" minLength={1} maxLength={60} type="text"
                                       value={title}
                                       onChange={handleTitle} required/>
                            </div>
                        </div>
                    </div>

                    <div className={"story-title-prev-story divide-y divide-black divide-dashed space-y-2"}
                         style={constants.accessibleStyle(accessibility)}>


                        {playerState.player?.current_story?.submissions.map<React.ReactNode>((s, idx) => {
                            return <div key={"submission-" + idx}>
                                <PrevSubmission playerId={playerState.player?.id as number}
                                                canFlag={playerState.player?.id !== s.player_id}
                                                submission={s} onToggle={(b) => handleFlag(b, s.id)}/>
                            </div>
                        })}

                    </div>
                </div>
            </GameFormPage></Waitable> : <Break/>
}
export default StoryTitle