import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Player} from "../../types";

export interface PlayerState {
    player?: Player
}


const initialPlayerState: PlayerState = {
    player: undefined
}


const playerSlice = createSlice({
    name: "playerSlice",
    initialState: initialPlayerState,
    reducers: {
        updatePlayer: (state, action: PayloadAction<Player>) => {
            action.payload.characters?.sort((a, b) => a.meta.locked ? 100000 : (b.meta.locked ? -100000 : (a.id - b.id)))

            action.payload.current_story?.submissions?.sort((a, b) => a.index - b.index)


            state.player = action.payload

        },
        updateSubmission: (state, action: PayloadAction<string>) => {
            if (state.player?.current_submission) {
                state.player.current_submission.content = action.payload
            }
        }
    }
})

export const {updatePlayer, updateSubmission} = playerSlice.actions
export const playerReducer = playerSlice.reducer
