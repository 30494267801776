import React, {useEffect} from "react";
import "./styles.css"
import {useAppSelector} from "../../../redux/store";
import GamePage from "../game-page/game-page";
import {GameMode} from "../../../types2";
import {Story} from "../../../types";
import moment from "moment/moment";
import {saveAs} from "file-saver";
import {storyToText} from "./story-results";
import {fileNameTxt, handleTwitter} from "./story-view";
import CanvasViewSolo from "../../common/canvas-view/canvas-view-solo";
import html2canvas from "html2canvas";
import {SafeJoin} from "../../../util";
import {useTranslation} from "react-i18next";
import {PrevSubmissionRead} from "../../common/prev-submission/prev-submission";
import {constants} from "../../../constants";

type ResultsProps = {
    onGameStats: () => void
}

const SoloLeaderboard = ({onGameStats}: ResultsProps) => {

    const gameState = useAppSelector(state => state.game)


    const gameSettings = gameState.game?.settings
    const accessibility = useAppSelector(state => state.player.player?.meta.accessibility)

    const story: Story = gameState.game?.stories ? gameState.game?.stories[0] : {} as Story


    let selected = story.characters?.filter(c => c.selected).map(c => c.name) ?? []

    const {t} = useTranslation('game')

    useEffect(() => {
        window.onbeforeunload = (e: BeforeUnloadEvent) => {
            e.returnValue = 'Are you sure?'
        }

        return () => {
            window.onbeforeunload = null
        }
    }, [])

    function playAgain() {
        window.open("https://franticfanfic.com", "_self")
    }

    function fileNameImage(story: Story) {
        return `FranticFanfic_${story.meta.title}_${moment().format("MMM_DD_YYYY")}.png`
    }


    async function takeScreenshot(e: React.MouseEvent<HTMLButtonElement>) {
        const canvasId = `canvas-${story.id}`
        const canvas = await html2canvas(document.querySelector(`#${canvasId}`) as HTMLElement, {scrollY: -window.scrollY});

        // @ts-ignore
        canvas.toBlob(blob => {
            saveAs(blob as Blob, fileNameImage(story))
        }, 'image/png')
    }

    const footer = <>
        <button className={"text-lg"}
                onClick={_ => playAgain()}>{t('storyResults.footers.default')}</button>
        <button className={"text-sm flex-grow mx-1"}
                onClick={handleSupportUs}>{t('storyResults.footers.likeGame')}<br/>{t('storyResults.footers.supportUs')}
        </button>
        <button className={"text-lg"}
                onClick={handleGameStats}>{t('storyResults.footers.gameStats')}</button>
    </>

    function handleSupportUs() {
        window.open("https://ko-fi.com/franticfanfic", "_blank")
    }

    function handleGameStats() {
        onGameStats()
    }

    function titlesJsx() {
        return [
            <span className={"font-bold underline"}>{story?.meta.title}</span>,
            <>characters: {SafeJoin(story?.characters && story.characters.filter(c => c.selected).map<React.ReactNode>(c =>
                <i key={"character-" + c.id}>{c.name}</i>), ', ')} </>]
    }

    function handleTextClick() {
        let text = storyToText(story)
        saveAs(new Blob([text], {type: "text/plain;charset=utf-8"}), fileNameTxt(story))
    }

    function subheaders() {
        if (gameState.game?.settings.game_mode !== GameMode.solo_mode) {
            return [t('storyResults.subheaders.react'), t('storyResults.subheaders.click'), t('storyResults.subheaders.download')]
        } else {
            return [t('storyResults.subheaders.downloadSolo'), t('storyResults.subheaders.tweetSolo')]
        }
    }

    return <GamePage footerJsx={footer} titlesJsx={titlesJsx()}
                     subHeaders={subheaders()}
                     hack={gameState.game?.stories?.map(s => <CanvasViewSolo
                         multiPrompt={gameState.game?.settings.multi_prompt} key={"canvas-" + s.id} story={s}/>)}>
        <div className={"story-view-container divide-y divide-gray-200"}>
            {story?.submissions.map<React.ReactNode>((s, idx) => {

                return <div className={"story-view-submission"} key={"story-" + s.id}
                            style={constants.accessibleStyle(accessibility)}>
                    <PrevSubmissionRead submission={s} isCensored={s.meta.censored}/>
                    {gameState.game?.settings.multi_prompt || s.index === story.submissions.length - 1 ?
                        <div className={"vote-row-buttons font-sans"}
                             style={{
                                 fontStyle: "italic",
                                 marginTop: "0.5rem"
                             }}>prompt: {s.meta.prompt}</div> : ''}
                </div>
            })}
        </div>


        <div className={"story-results2-container2 story-results2-button-border"}>
            <div className={"story-download-container py-2"}>
                <button className={"story-list-download-all"} onClick={takeScreenshot}>Download Image
                </button>
                <button className={"story-list-download-all"} onClick={handleTextClick}>Download Text</button>

                <button className={"story-list-download-all"}
                        onClick={() => handleTwitter(story.id, story.meta.title.toUpperCase(), ...selected)}>{"send tweet"}</button>

            </div>
        </div>

    </GamePage>
}

export default SoloLeaderboard