import React from "react";
import squarestore from "../images/squarestore.png"
import zine from "../images/zine.webp"
import StyledHeader from "../components/common/styled-header/styled-header";
import BodyTitle from "../components/common/body-title/body-title";
import Body from "../components/common/body/body";
import BodyText from "../components/common/body-text/body-text";
import BodyList, {BodyListItem} from "../components/common/body-list/body-list";
import Styled from "../components/common/styled/styled";

export const body = {
    content: <Body>

        {/*<p style={{marginTop: 0}}><u><i>SUPPORT US BY BUYING OUR MERCH: </i></u></p>*/}
        <StyledHeader header={"BUY OUR MERCH:"}
                      subHeader={"It's all dropshipping lol."}/>

        <br/>

        <a href={"https://franticfanfic.square.site"}><img width={"100%"} height={"auto"} src={squarestore}
                                                           alt={squarestore}/></a>
        {/*<BodyText>We have a merch site!!! Cool!! It's all dropshipping (which means it's print-on-demand... we live in a*/}
        {/*    one*/}
        {/*    bedroom apartment and can't really afford the space needed for stock LOL. </BodyText>*/}
        <BodyText>Click the image or HERE! to get to our merch site! <a
            href={"https://franticfanfic.square.site"}>franticfanfic.square.site</a></BodyText>

        <StyledHeader header={"buy our zine:"} subHeader={"Physical or digital copies available."}/>
        <br/>
        <a href={"https://ko-fi.com/s/42a7b48e13"}><img height={"auto"} width={"100%"} src={zine} alt={zine}/></a>

        <BodyText>
            We made this 82 page zine! It's got comics and behind the scenes things and also a code to find the hidden
            playable character!
        </BodyText>
        <BodyText>There's three ways to get it:
            <BodyList ordered>
                <BodyListItem>Digital / <a href={"https://franticfanfic.com/supporter"}>Supporter Membership</a>
                </BodyListItem>
                <BodyList>
                    {/*<BodyListItem>pdf</BodyListItem>*/}
                    {/*<BodyListItem>pdf</BodyListItem>*/}
                </BodyList>
                <BodyListItem>Digital / <a href={"https://ko-fi.com/s/42a7b48e13"}>PDF Download</a></BodyListItem>
                <BodyListItem>Physical / In-Person</BodyListItem>

            </BodyList>

        </BodyText>

        {/*<BodyText>Oh yeah! We also made this 82 page zine! <a>Click the picture*/}
        {/*    or HERE*/}
        {/*    to order it</a>, it's $5 and all proceeds go to the game.</BodyText>*/}
        {/*<p>It's got comics and behind the scenes things and also a code to find the hidden playable character!</p>*/}

        {/*<p style={{marginTop: 0}}><u><i>SUPPORT US WITH KO-FI: </i></u></p>*/}

        <StyledHeader header={"KO-FI"} subHeader={"Send a tip to the devs."}/>
        {/*<BodyTitle></BodyTitle>*/}

        <BodyText> Did you LIKE THE GAME? <a href={"https://ko-fi.com/franticfanfic"}>Do you want to
            SEND
            US A
            LITTLE SOMETHIN' SOMETHIN' to thank us? SEND US A KO-FI!</a>
        </BodyText>
        {/*<iframe src='https://ko-fi.com/franticfanfic/?hidefeed=true&widget=true&embed=true&preview=true'*/}
        {/*        style={{border: "none", width: "100%", padding: "4px", background: "#f7f7f7"}} height='630'*/}
        {/*        title='zeekayart'/>*/}
        <BodyText>Ko-Fi helps us keep the servers on so we want to thank everyone with some Ko-Fi
            perks! Here's our stretch goals!</BodyText>
        <BodyList>
            <BodyListItem>$200 - New character - !UNLOCKED! [HEDGEE]</BodyListItem>
            <BodyListItem>$500 - New NEW character - !UNLOCKED! [SCRAMBLES]</BodyListItem>
            <BodyListItem>$1000 - New game mode - *LOCKED*</BodyListItem>
        </BodyList>


        {/*<p>*/}
        {/*    <br/>*/}
        {/*    <u><i>SUPPORT US BY SPREADING THE WORD OF FRANTIC FANFIC!</i></u>*/}
        {/*</p>*/}
        {/*<BodyTitle>SUPPORT US BY SPREADING THE WORD </BodyTitle>*/}
        <StyledHeader header={"SPREAD THE WORD"} subHeader={"How you can help with $0."}/>

        <BodyText>

            Spread the game around! Share your Frantic Fanfics, stream yourself playing
            it, use the
            #franticfanfic hashtag and ask your favourite streamers
            (politely) if they'd consider playing it.
        </BodyText>

        {/*<u><i>FOLLOW THE DEV TEAM ON TWITTER and TWITCH</i></u>*/}
        {/*<BodyTitle>FOLLOW THE DEV TEAM ON TWITTER and TWITCH</BodyTitle>*/}
        <StyledHeader header={"Follow the dev team"} subHeader={"Check out our socials."}/>

        <BodyText>This one is just for our egos lol but yeah that would be cool too! Plus you never know what we'll be
            up to in
            The future!! And yeah Michael doesn't really update or care about social media lol but the more eyeballs on
            our stuff the better, right?! </BodyText>
        <BodyList>

            <BodyListItem> <a href={"https://twitter.com/zeekayart"}>@ZEEKAYART</a> / TWITTER</BodyListItem>
            <BodyListItem> <a href={"        https://www.youtube.com/zeekayart\n"}>@ZEEKAYART</a> / YOUTUBE
            </BodyListItem>

            <BodyListItem> <a href={"https://twitch.tv/zeekayart"}>@ZEEKAYART</a> / TWITCH
            </BodyListItem>
            <BodyListItem> <a href={"https://www.instagram.com/zeekayart/"}>@ZEEKAYART</a> / INSTAGRAM
            </BodyListItem>
            <BodyListItem> <a href={"https://www.tiktok.com/@zeetheraccoon"}>@ZEETHERACCOON</a> / TIKTOK
            </BodyListItem>
            <BodyListItem> <a href={"https://twitter.com/MichaelAngsten"}>@MICHAELANGSTEN</a> / TWITTER
            </BodyListItem>


        </BodyList>
        {/*<BodyText>*/}

        {/*</BodyText>*/}
        {/*<BodyText>*/}
        {/*</BodyText>*/}
        {/*<BodyText>*/}
        {/*</BodyText>*/}


        {/*<BodyText>*/}
        {/*</BodyText>*/}
        <StyledHeader header={"wanna buy our game?"}
                      subHeader={"Are you a representative from a large company and/or a filthy rich person?"}/>
        {/*<BodyTitle>ARE YOU A REPRESENTATIVE FROM A LARGE COMPANY AND/OR FILTHY RICH PERSON AND YOU WANT TO BUY OUR*/}
        {/*    GAME?</BodyTitle>*/}
        {/*<u><i>ARE YOU A REPRESENTATIVE FROM A LARGE COMPANY AND/OR FILTHY RICH PERSON AND YOU WANT TO BUY OUR*/}
        {/*    GAME?</i></u>*/}
        <BodyText> What is this, Dragon's Den? Should we roleplay that a bit? Do you want to buy us? Do you have
            millions of
            dollars and want to shove it in our faces? Cool sounds good, email us at franticfanficgame [at] gmail [dot]
            com.</BodyText>


    </Body>
}

