import {blogs} from "../../../text/blog/blogs"

import "./styles.css"
import React, {useState} from "react";
import MenuPage from "../menu-page/menu-page";
import LinkButton from "../link-button/link-button";
import StyledHeader from "../../common/styled-header/styled-header";
import BodyText from "../../common/body-text/body-text";
import BodyTitle from "../../common/body-title/body-title";


export const BlogContent = () => {

    function latestPost() {
        return blogs[blogs.length - 1]
    }

    function header() {
        return <LinkButton path={"/blog"} buttonText={"Developer Blog:"}/>

    }

    return latestPost() ? <>
        {/*{header()}*/}
        <div className={"flex flex-row justify-center"}>
            <StyledHeader header={"Developer Blog"} linkPath={"/blog"}
                          subHeader={<><p className={"font-sans"}>{latestPost().title}</p>
                        <p className={"font-sans"}>{latestPost().author}</p></>}/>
            {/*<div className={"fake-button bg-gray-med p-2 w-3/4 justify-center"}>*/}
            {/*    <h3 className={"font-comic mt-2 underline"}>{latestPost().title}</h3>*/}
            {/*    <h4 className={"font-comic"}>{latestPost().author}</h4>*/}
            {/*</div>*/}
        </div>

        <BodyText>{latestPost().content}</BodyText></> : <></>
}


const Blog = () => {

    const pageSize = 5;

    const [page, setPage] = useState(0)


    function posts() {
        return blogs.slice(-1 - (page + 1) * pageSize, -1 - (page * pageSize)).reverse();
    }

    function handlePrevious() {
        setPage(prevState => Math.max(0, prevState - 1))
    }

    function handleNext() {
        setPage(prevState => Math.min(Math.floor((blogs.length - 1) / pageSize), prevState + 1))
    }

    function footer() {
        return <div className={"blog-container-footer-buttons"}>
            <button className={"blog-container-footer-button"} disabled={page === 0} onClick={handlePrevious}>previous
            </button>
            <button className={"blog-container-footer-button"}
                    disabled={page === Math.floor((blogs.length - 1) / pageSize)}
                    onClick={handleNext}>next
            </button>
        </div>
    }


    return <MenuPage subHeaders={["news from the dev team"]} titles={["blog"]} footerJsx={footer()}>
        <div className={"blog-container divide-y divide-gray-200 space-y-8"}>
            {posts().map(p => <div>
                <StyledHeader header={p.title} subHeader={p.author}/>
                <BodyText>{p.content}</BodyText>

            </div>)}
        </div>
    </MenuPage>
}

export default Blog