import {Picker, PickerOption} from "./picker";
import GameSettingsLabel from "../game-settings-label/game-settings-label";


type Props = {
    label: string
    subLabel?: string

    options: PickerOption<number>[]

    value: number
    onSelect: (val: number) => void

}

const FullPicker: React.FunctionComponent<Props> = (props) => {
    return <div className={"square-radio-group"}>
        <GameSettingsLabel label={props.label} subLabel={props.subLabel}/>

        <div className={"create-game-button-container"}>
            <Picker value={props.value} onSelect={v => props.onSelect(v)}
                    options={props.options}/>

        </div>
    </div>
}

export default FullPicker