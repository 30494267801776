import {Countdown} from "../../../types";
import {useCountdown} from "../countdown/countdown";

type Props = {
    countdown: Countdown
    onClick: () => void
    loading?: boolean
    btnText?: string
    disabled?: boolean

}

const CountdownComponent2 = ({countdown, onClick, loading, btnText, disabled}: Props) => {

    function handleClick() {
        onClick()
    }

    const timeRemaining = useCountdown(countdown)

    const secondsRemaining = -1 * timeRemaining / 1000;
    const paddedMinutes = Math.floor(secondsRemaining / 60).toString().padStart(2, "0");
    const paddedSeconds = (Math.floor(secondsRemaining) % 60).toString().padStart(2, "0")

    return <div className={"relative flex-grow text-center text-xl flex justify-start items-center"}>
        <strong>{paddedMinutes}:{paddedSeconds}</strong>
        <div className={"h-full self-center absolute right-0"}>
            {btnText !== undefined ? <button className={"text-xl h-full"} disabled={loading || disabled}
                                             onClick={handleClick}>{loading ? "loading..." : btnText}</button> : ''}

        </div>
    </div>

}

export default CountdownComponent2